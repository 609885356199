
import { defineComponent } from "vue";
import RentedBuildingPolicyInformation from "@/views/reusable/policies/policy-information/house-insurance/RentedBuildingPolicyInformation.vue";

export default defineComponent({
  name: "broker-client-rented-building-policy-information",
  components: {
    RentedBuildingPolicyInformation
  },
  created() {
    //Set page title
    document.title =
      "Rented Building Policy Information | " + process.env.VUE_APP_TITLE;
  }
});
